import { createApp } from 'vue';
import { createPinia } from 'pinia';
import LmsNewYearsReception from 'lms-components/src/components/data-display/newYearsReception/LmsNewYearsReception.vue';
import type { NewYearsReceptionItems } from 'lms-components/src/types/componentTypes';
import i18n from '@/translations';

export function initNewYearsReception(newYearsReception: HTMLElement) {
  const items = newYearsReception.dataset.items ? JSON.parse(newYearsReception.dataset.items ?? '[]') : [];

  const itemsRedCarped = items[0];
  const itemsImpressions = items[1];

  const newYearsReceptionItems: NewYearsReceptionItems = {
    impressions: itemsImpressions,
    yellowCarpet: itemsRedCarped,
  }

  createApp(LmsNewYearsReception, {
    items: newYearsReceptionItems,
  })
    .use(i18n)
    .use(createPinia())
    .mount(newYearsReception);
}
