import 'vite/modulepreload-polyfill';
import './scss/main.scss';
import './css/generated/generated.css';
import { domReady } from './functions/DomReady';
import { initHeader } from '@/functions/Page/headerFunctions';
import { initFooter } from "@/functions/Page/footerFunctions";
import { initGlobalStorageEvents } from '@/Utilities/misc';
import {initLinkList} from '@/functions/Content/linkListFunction';
import { initFairCalendar } from '@/functions/fairCalendar/fairCalendarFunctions';
import { initSponsors } from "@/functions/Page/sponsorsFunctions";
import { initHeroImage } from "@/functions/Page/heroImageFunction";
import { initQuickFacts } from "@/functions/Content/quickFactsFunctions";
import { ThemeModifier } from 'lms-components/src/Utilities/ThemeModifier'
import { initNewYearsReception } from '@/functions/newYearsReception/newYearsReceptionFunction';

// import './Classes/Configuration';
// import {initTouchClass} from '@/Classes/Utility/DetectTouchController';
// import {detectAdBlockController} from '@/Classes/Utility/DetectAdBlockController';

if (import.meta.hot) {
  import.meta.hot.on('vite:afterUpdate', () => {
    const event = new CustomEvent('RerenderTicketshop');
    window.dispatchEvent(event);
  });
}

initGlobalStorageEvents();
ThemeModifier.setThemeColors();

domReady(async () => {
    // initTouchClass();
    // detectAdBlockController();

  const header: HTMLElement | null = document.querySelector('.new-header');
  const footer: HTMLElement | null = document.querySelector('.new-footer');
  const linkList: NodeListOf<HTMLElement> | null = document.querySelectorAll('.lms-linkList');
  const fairCalendar: HTMLElement | null = document.querySelector('.lms-fair-calendar');
  const quickFacts: HTMLElement | null = document.querySelector('.lms-quick-facts');
  const partners: HTMLElement | null = document.querySelector('.lms-sponsors');
  const heroImage: HTMLElement | null = document.querySelector('.lms-hero-image');
  const newYearsReception: HTMLElement | null = document.querySelector('.lms-new-years-reception');

  if (header) {
    initHeader(header);
  }
  if (footer) {
    initFooter(footer);
  }
  if (linkList?.length) {
    linkList.forEach((linkListItem) => {
      initLinkList(linkListItem);
    });
  }
  if (quickFacts) {
    initQuickFacts(quickFacts);
  }
  if (partners) {
    initSponsors(partners);
  }
  if (heroImage) {
    initHeroImage(heroImage);
  }
  if (fairCalendar) {
    document.querySelector('.wrapper')?.classList.add('wrapper--fairCalendar');
    initFairCalendar(fairCalendar);
  }

  if (newYearsReception) {
    initNewYearsReception(newYearsReception);
  }
});
